import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class SeasonToggle {
    constructor() {
        this.initSeasonToggle();
    }
    initSeasonToggle(){
        const body = document.getElementsByTagName( 'body' )[0];
        const seasonSwitch = Util.$('.js-season-toggle');
        const thisEvent = new Event('seasonalchange');
        // On page load check season toggle query parameter
        let urlSearchParams = new URLSearchParams(window.location.search);
        let season = urlSearchParams.get('season');
        const s = this;
        // TEST FOR SEASON SESSION STORAGE IF NO URL SEARCH PARAMS
        if(season != 'winter' && season != 'summer'){
            let sessionData = sessionStorage.getItem("season");
            if(sessionData === "winter"){
                seasonSwitch.forEach(function(el){
                    const winterSwitch = Util.$('.js-toggle.-winter', el)[0];
                    const summerSwitch = Util.$('.js-toggle.-summer', el)[0];
                    const indicator = Util.$('.js-main-nav__switch', el)[0];
                    summerSwitch.classList.remove('-active');
                    indicator.classList.remove('-summer');
                    indicator.classList.add('-winter');
                    winterSwitch.classList.add('-active');
                });
                body.classList.add('-winter-season');
                body.classList.remove('-summer-season');
                urlSearchParams.set('season', 'winter');
                let newRelativePathQuery = window.location.pathname + '?' + urlSearchParams.toString();
                history.pushState(null, '', newRelativePathQuery);
            }else if(sessionData === "summer"){
                seasonSwitch.forEach(function(el){
                    const winterSwitch = Util.$('.js-toggle.-winter', el)[0];
                    const summerSwitch = Util.$('.js-toggle.-summer', el)[0];
                    const indicator = Util.$('.js-main-nav__switch', el)[0];
                    winterSwitch.classList.remove('-active');
                    summerSwitch.classList.add('-active');
                    indicator.classList.remove('-winter');
                    indicator.classList.add('-summmer');
                });
                body.classList.add('-summer-season');
                body.classList.remove('-winter-season');
                urlSearchParams.set('season', 'summer');
                let newRelativePathQuery = window.location.pathname + '?' + urlSearchParams.toString();
                history.pushState(null, '', newRelativePathQuery);
            }else{
                //CHECK FOR BODY CLASS NAME
                if(body.classList.contains('-summer-season')){
                    seasonSwitch.forEach(function(el){
                        const winterSwitch = Util.$('.js-toggle.-winter', el)[0];
                        const summerSwitch = Util.$('.js-toggle.-summer', el)[0];
                        const indicator = Util.$('.js-main-nav__switch', el)[0];
                        winterSwitch.classList.remove('-active');
                        summerSwitch.classList.add('-active');
                        indicator.classList.remove('-winter');
                        indicator.classList.add('-summmer');
                    });
                }else if(body.classList.contains('-winter-season')){
                    seasonSwitch.forEach(function(el){
                        const winterSwitch = Util.$('.js-toggle.-winter', el)[0];
                        const summerSwitch = Util.$('.js-toggle.-summer', el)[0];
                        const indicator = Util.$('.js-main-nav__switch', el)[0];
                        winterSwitch.classList.add('-active');
                        summerSwitch.classList.remove('-active');
                        indicator.classList.remove('-summer');
                        indicator.classList.add('-winter');
                    });
                }
            }
        }
        if(seasonSwitch){
            window.addEventListener("seasonalchange", (e) => {
                let season = sessionStorage.getItem("season");
                seasonSwitch.forEach(function(el){
                    const winterSwitch = Util.$('.js-toggle.-winter', el)[0];
                    const summerSwitch = Util.$('.js-toggle.-summer', el)[0];
                    const indicator = Util.$('.js-main-nav__switch', el)[0];
                    if(season === "winter" && indicator.classList.contains('-summer')){
                        indicator.classList.add('-winter');
                        indicator.classList.remove('-summer');
                        winterSwitch.classList.add('-active');
                        summerSwitch.classList.remove('-active');
                    }else if(season === "summer" && indicator.classList.contains('-winter')){
                        indicator.classList.remove('-winter');
                        indicator.classList.add('-summer');
                        winterSwitch.classList.remove('-active');
                        summerSwitch.classList.add('-active');
                    }
                });
            });

            seasonSwitch.forEach(function(el){
                const winterSwitch = Util.$('.js-toggle.-winter', el)[0];
                const summerSwitch = Util.$('.js-toggle.-summer', el)[0];
                const indicator = Util.$('.js-main-nav__switch', el)[0];
                if(season === 'winter'){
                    body.classList.add('-winter-season');
                    body.classList.remove('-summer-season');
                    winterSwitch.classList.add('-active');
                    indicator.classList.add('-winter');
                    sessionStorage.setItem("season", "winter");
                }else if(season === 'summer'){
                    body.classList.add('-summer-season');
                    body.classList.remove('-winter-season');
                    summerSwitch.classList.add('-active');
                    sessionStorage.setItem("season", "winter");
                }else{
                    if(indicator.classList.contains('-winter')){
                        winterSwitch.classList.add('-active');
                    }else{
                        summerSwitch.classList.add('-active');
                    }
                }
                
                winterSwitch.addEventListener('click', function(ev){
                    ev.preventDefault();
                    if(!indicator.classList.contains('-winter')){
                        sessionStorage.setItem("season", "winter");
                        s.winterToggle(body, winterSwitch, summerSwitch, indicator, urlSearchParams, thisEvent);
                        urlSearchParams.set('season', 'winter');
                        let newRelativePathQuery = window.location.pathname + '?' + urlSearchParams.toString();
                        history.pushState(null, '', newRelativePathQuery);
                    }
                });
                summerSwitch.addEventListener('click', function(ev){
                    ev.preventDefault();
                    if(!indicator.classList.contains('-summer')){
                        sessionStorage.setItem("season", "summer");
                        s.summerToggle(body, winterSwitch, summerSwitch, indicator, urlSearchParams, thisEvent);
                        urlSearchParams.set('season', 'summer');
                        let newRelativePathQuery = window.location.pathname + '?' + urlSearchParams.toString();
                        history.pushState(null, '', newRelativePathQuery);
                    }
                });
                const infoBtn = Util.$('.js-season-toggle__info-btn', el)[0];
                const infoBubble = Util.$('.js-season-toggle__info')[0];
                const infoBubbleCloseBtn = Util.$('.js-season-toggle__btn')[0];
                if(infoBtn){
                    infoBtn.addEventListener('click', function(ev){
                        ev.preventDefault();
                        if(infoBubble.classList.contains('-active')){
                            infoBubble.classList.remove('-active');
                        }else{
                            infoBubble.classList.add('-active')
                        }
                    });
                    infoBubbleCloseBtn.addEventListener('click', function(ev){
                        ev.preventDefault();
                        infoBubble.classList.remove('-active');
                    });
                }
            });
        }
        
        
    }
    
    winterToggle(body, winterSwitch, summerSwitch, indicator, urlSearchParams, thisEvent){
        summerSwitch.classList.remove('-active');
        indicator.classList.remove('-summer');
        indicator.classList.add('-winter');
        winterSwitch.classList.add('-active');
        body.classList.add('-winter-transition');
        urlSearchParams.set('season', 'winter');
        window.dispatchEvent(thisEvent);
        setTimeout(function(){
            body.classList.add('-winter-season');
            body.classList.remove('-summer-season');
            
          }, 350);
        setTimeout(function(){
            body.classList.remove('-winter-transition');
          }, 360);
    }
    summerToggle(body, winterSwitch, summerSwitch, indicator, urlSearchParams, thisEvent){
        body.classList.add('-summer-transition');
        winterSwitch.classList.remove('-active');
        summerSwitch.classList.add('-active');
        indicator.classList.remove('-winter');
        indicator.classList.add('-summmer');
        urlSearchParams.set('season', 'summer');
        window.dispatchEvent(thisEvent);
        setTimeout(function(){
            body.classList.remove('-winter-season');
            body.classList.add('-summer-season');
            
            }, 350);
        setTimeout(function(){
            body.classList.remove('-summer-transition');
            }, 360);
    }   
}
export default SeasonToggle;