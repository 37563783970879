import Swiper, { Navigation, Mousewheel } from 'swiper';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

class MountainConditions {
    constructor() {
        this.initMountainConditions();
    }
    initMountainConditions(){

        // Global variables
        const html = document.querySelector('html');
        const isSV = window.location.href.includes('sv.html') || window.location.href.includes('sunvalley');
        const isSB = window.location.href.includes('sb.html') || window.location.href.includes('snowbasin');
        //const isSafari = typeof window.GestureEvent === 'function';

        if (isSV)
            html.classList.add('isSV')
        else if (isSB)
            html.classList.add('isSB');
        //if (isSafari)
            //html.classList.add('isSafari');

        html.classList.remove('no-js');
        
        // Media queries
        const isLargeScreen = window.matchMedia('all and (min-width: 1024px)').matches;

        // Resize trail tables for flex column wrap
        const tables = document.querySelectorAll('.js-mc__table');
        if (tables.length > 0) {
            for (let table of tables) {
                const tbody = table.querySelector('tbody');
                const trHeight = tbody.querySelector('tr').offsetHeight;
                const trCount = tbody.childElementCount;

                if (trCount > 5) {
                    if (isLargeScreen) {
                        tbody.setAttribute('style', 'height:' + (Math.round(trCount / 2) * trHeight) + 'px');
                    }
                }
            }
        }

        // Initiate parking progress bar

        const parkingGraph = document.getElementById('mc-parking__graph');

        if (parkingGraph) {
            const parkingGraphData = JSON.parse(decodeURIComponent(parkingGraph.getAttribute('data-values')));
            const parkingColorPlugin = {
                id: 'customCanvasBackgroundColor',
                beforeDraw: (chart, args, options) => {
                    const {ctx} = chart;
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = options.color || '#E9EEF4';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                }
            };
        
            new Chart(parkingGraph, {
                type: 'bar',
                data: {
                    labels: [''],
                    datasets: [{
                        label: '',
                        data: parkingGraphData
                    }]
                },
                options: {
                scales: {
                    y: {
                        beginAtZero: true
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                },
                backgroundColor: '#1D4F91',
                indexAxis: 'y',
                barPercentage: 1,
                autoPadding: false,
                plugins: {
                    customCanvasBackgroundColor: {
                        color: '#E9EEF4'
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    },
                    title: {
                        display: false
                    }
                }
                },
                plugins: [parkingColorPlugin]
            });
        }
        

        // Initiate lifts bar graphs

        const barGraphs = document.querySelectorAll('.js-mc__graph-bar-canvas');

        if (barGraphs[0]) {

            for (let graph of barGraphs) {

                const graphData = JSON.parse(decodeURIComponent(graph.getAttribute('data-values')));
                let graphColor = '';
                let canvasColor = '';
                if (isSV) {
                    graphColor = '#a4792c';
                    canvasColor = '#193D67';
                } else if (isSB) {
                    graphColor = '#fccf45';
                    canvasColor = '#235ead';
                }
                const backgroundColorPlugin = {
                    id: 'customCanvasBackgroundColor',
                    beforeDraw: (chart, args, options) => {
                        const {ctx} = chart;
                        ctx.save();
                        ctx.globalCompositeOperation = 'destination-over';
                        ctx.fillStyle = options.color || '#E9EEF4';
                        ctx.fillRect(0, 0, chart.width, chart.height);
                        ctx.restore();
                    }
                };
    
                new Chart(graph, {
                    type: 'bar',
                    data: {
                        labels: [''],
                        datasets: [{
                            label: '',
                            data: graphData
                        }]
                    },
                    options: {
                        scales: {
                            y: {
                                beginAtZero: true
                            },
                            x: {
                                grid: {
                                    display: false
                                }
                            }
                        },
                        backgroundColor: graphColor,
                        indexAxis: 'y',
                        barPercentage: 1,
                        autoPadding: false,
                        plugins: {
                            customCanvasBackgroundColor: {
                                color: canvasColor
                            },
                            legend: {
                                display: false
                            },
                            tooltip: {
                                enabled: false
                            },
                            title: {
                                display: false
                            }
                        }
                    },
                    plugins: [backgroundColorPlugin]
                });
    
            }

        }

        
        // Initiate trails pie graphs

        const pieGraphs = document.querySelectorAll('.js-mc__graph-pie-canvas');

        if (pieGraphs[0]) {

            for (let graph of pieGraphs) {

                const graphData = JSON.parse(decodeURIComponent(graph.dataset.values));
                const graphColors = JSON.parse(decodeURIComponent(graph.dataset.colors));
                const graphLabels = graph.hasAttribute('data-labels');
    
                if (graphLabels) {

                    if (isSV) {
                        new Chart(graph, {
                            type: 'pie',
                            data: {
                                labels: [''],
                                datasets: [{
                                    label: '',
                                    data: graphData,
                                    backgroundColor: graphColors
                                }]
                            },
                            options: {
                                borderWidth: 0,
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    title: {
                                        display: false
                                    },
                                    datalabels: {
                                        color: '#FFFFFF',
                                        font: {
                                            size: '50px',
                                            family: 'FreightDisplayProBold',
                                            weight: '500'
                                          }
                                    }
                                }
                            },
                            plugins: 
                                [ChartDataLabels]
                        });
                    } else if (isSB) {
                        new Chart(graph, {
                            type: 'pie',
                            data: {
                                labels: [''],
                                datasets: [{
                                    label: '',
                                    data: graphData,
                                    backgroundColor: graphColors
                                }]
                            },
                            options: {
                                borderWidth: 0,
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                    tooltip: {
                                        enabled: false
                                    },
                                    title: {
                                        display: false
                                    },
                                    datalabels: {
                                        color: '#FFFFFF',
                                        font: {
                                            size: '50px',
                                            family: 'AspiraLight',
                                            weight: '500'
                                        }
                                    }
                                }
                            },
                            plugins: 
                                [ChartDataLabels]
                        });
                    }
                    
                } else {
                    new Chart(graph, {
                        type: 'pie',
                        data: {
                            labels: [''],
                            datasets: [{
                                label: '',
                                data: graphData,
                                backgroundColor: graphColors
                            }]
                        },
                        options: {
                            borderWidth: 0,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false
                                },
                                title: {
                                    display: false
                                }
                            }
                        }
                    });
                }
    
            }

        }


        // On window load

        window.addEventListener('load', () => {


            // Initiate forecast sliders

            const forecastSliders = document.querySelectorAll('.js-mc-forecast__slider');

            if (forecastSliders[0]) {

                let swiper = new Swiper('.js-mc-forecast__slider', {
                    modules: [Navigation],
                    slidesPerView: "auto",
                    spaceBetween: 25,
                    mousewheel: {
                        forceToAxis: true,
                    },
                    navigation: {
                        nextEl: '.mc__ctrl-slide-btn-next',
                        prevEl: '.mc__ctrl-slide-btn-prev',
                    }
                });
            }
            
            // Add tab functionality

            const ctrls = document.querySelectorAll('.js-mc__ctrl-tab-btn');

            if (ctrls[0]) {

                for (let ctrl of ctrls) {

                    const tabName = ctrl.getAttribute('data-tab');
                    const tabs = document.querySelectorAll('.js-mc__tab[data-tab="' + tabName + '"]');

                    if (tabs.length > 0) {

                        for (let tab of tabs) {
                            var tabSiblings = n => [...n.parentElement.children].filter(c=>c!=n);
                            var ctrlSiblings = n => [...n.parentElement.parentElement.children].filter(c=>c!=n);
                            
                            ctrl.addEventListener('click', () => {
                                for (let tabSibling of tabSiblings(tab)) {
                                    tabSibling.classList.remove('-active');
                                }
                                for (let ctrlSibling of ctrlSiblings(ctrl)) {
                                    ctrlSibling.querySelector('.js-mc__ctrl-btn').classList.remove('-active');
                                }
                                tab.classList.add('-active');
                                ctrl.classList.add('-active');
                            });
                        }
                    }
                }

            }


            // Force click on first control for all control groups to set default active state

            const ctrlGroups = document.querySelectorAll('.js-mc__ctrls');

            if (ctrlGroups[0]) {

                for (let group of ctrlGroups) {
                    let firstCtrl = group.querySelectorAll('.js-mc__ctrl-btn')[0];
                    firstCtrl.click(); 
                }

            }

            const tabGroups = document.querySelectorAll('.js-mc__ctrls-tabs');

            if (tabGroups[0]) {
                for (let tabGroup of tabGroups) {
                    let firstTab = tabGroup.querySelector('.js-mc__ctrl-tab').querySelector('.js-mc__ctrl-tab-btn');
                    firstTab.click(); 
                }
            }
 
        });
    }
}
export default MountainConditions;
