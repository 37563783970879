import Util from "../utils/util.js";
import Glide from '@glidejs/glide';
class FeatureMedia {
    constructor() {
        this.initFeatureMedia();
    }
    initFeatureMedia(){
        let featureMedia = Util.$('.js-feature-media');
        if(featureMedia.length> 0){
            featureMedia.forEach(el => {
                let slider = Util.$('.js-feature-media__slider', el);
                if(slider.length > 0){
                    let activeSlideIndicator = Util.$('.js-feature-media__ctrl-count-current', el)[0];
                    let totalSlideIndicator = Util.$('.js-feature-media__ctrl-count-total', el)[0];
                    let slideCount = Util.$('.js-glide__slide', el).length;
                    let current = 1;
                    let activeSlide = '';
                    let canShift = false;
                    let showMultiple = el.classList.contains('-show-multiple');
                    let thisSlider = "";
                    if(!showMultiple){
                        thisSlider = new Glide(el, {
                            rewind : false,
                            animationDuration : 350,
                            animationTimingFunc: 'ease-out'
                        });
                    }else{
                        thisSlider = new Glide(el, {
                            rewind : false,
                            animationDuration : 350,
                            animationTimingFunc: 'ease-out',
                            gap: 30
                        });
                    }

                    thisSlider.on('run.before', function(ev){
                        activeSlide = Util.$('.glide__slide.glide__slide--active', el)[0];
                        
                        if(ev.direction === ">"){
                            if(current < slideCount){
                                current = current + 1;
                                canShift = true;
                            }else{
                                canShift = false;
                            }
                            
                        }else{
                            if(current > 1){
                                current = current - 1;
                                canShift = true;
                            }else{
                                canShift = false;
                            }
                        }
                        if(canShift){
                            if(!showMultiple){
                                activeSlide.classList.add('-leaving');
                            }
                            if (activeSlideIndicator)
                                activeSlideIndicator.textContent = current;
                        }
                        
                    });
                    thisSlider.on('run.after', function(ev){
                        if(canShift && !showMultiple){
                            activeSlide.classList.remove('-leaving');
                        }
                    });
                    thisSlider.on('mount.before', function(ev){
                        if (activeSlideIndicator)
                            activeSlideIndicator.textContent = current;
                        if (totalSlideIndicator)
                            totalSlideIndicator.textContent = slideCount;
                    });
                    thisSlider.mount();
                }
            });
        }
        
    }
}
export default FeatureMedia;