import Header from "./global/header.js";
import MainNav from "./global/main-nav.js";
import Search from "./global/search.js";
import Breadcrumbs from "./global/breadcrumbs.js";
import Video from "./widgets/video.js";
import Accordion from "./widgets/accordion.js";
import TrailMap from "./widgets/trail-map.js";
import Alerts from "./global/alert.js";
import LocalNav from "./global/local-nav.js";
import Filters from "./global/filters.js";
import LoadMore from "./global/load-more.js";
import Tabs from "./global/tabs.js";
import FeatureMedia from "./page-type-components/feature-media.js";
import Weddings from "./page-type-components/weddings.js";
import MountainConditions from "./page-type-components/mountain-conditions.js";
import CustomCards from "./widgets/custom-cards.js";
import SeasonToggle from "./widgets/season-toggle.js";
import MountainReportDropdown from "./global/mountain-report-dropdown.js";
import VisitInfo from "./page-type-components/visit-info.js";
import Welcome from "./page-type-components/welcome.js";
import AchievementInfo from "./page-type-components/achievement-info.js";

class FeCore {
    constructor() {
        this.initCore();
        
    }
    initCore(){   
        
        const header = new Header;
        const mainnav = new MainNav;
        const search = new Search;
        const breadcrumbs = new Breadcrumbs;
        const video = new Video;
        const trailMap = new TrailMap;
        const alerts = new Alerts;
        const localnav = new LocalNav;
        const filters = new Filters;
        const loadMore = new LoadMore;
        const tabs = new Tabs;
        const featureMedia = new FeatureMedia;
        const weddings = new Weddings;
        const mountainConditions = new MountainConditions;
        const accordion = new Accordion;
        const customCards = new CustomCards;
        const seasonToggle = new SeasonToggle;
        const mountainReportDropdown = new MountainReportDropdown;
        const visitInfo = new VisitInfo;
        const welcome = new Welcome;
        const achievementInfo = new AchievementInfo;
    }
}
export default FeCore;