import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Welcome {
    constructor() {
        this.initWelcome();
    }
    initWelcome(){
        const welcome = Util.$('.js-welcome__dismissable');
        if(welcome.length> 0){
            const sessionName = 'welcome' + welcome[0].getAttribute('data-message');
            const dismissBtn = Util.$('.js-welcome__dismiss-btn', welcome[0]);
            let sessionData = localStorage.getItem(sessionName);
            if(sessionData != 'dismissed'){
                const welcomeContent = Util.$('.js-welcome__dismissable-wrapper', welcome[0]);
                let height =  welcomeContent.offsetHeight;
                let tween = {height: ''};
                tween.height = height;
                tween.onComplete = function(message){
                    message.classList.remove('-dismissed');
                };
                tween.onCompleteParams = [welcome[0]];
                gsap.to(welcome[0], tween);
                dismissBtn[0].addEventListener('click', function(ev){
                    let tween = {height: ''};
                    console.log('CLICKED');
                    tween.height = 0;
                    tween.onComplete = function(message){
                        message.classList.add('-dismissed');
                        
                    };
                    tween.onCompleteParams = [welcome[0]];
                    gsap.to(welcome[0], tween);
                    localStorage.setItem(sessionName, 'dismissed');
                });
            }
        }
        
    }
}
export default Welcome;