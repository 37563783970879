import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Breadcrumbs {
    constructor() {
        this.initBreadcrumbs();
    }
    settings = {
        desktop: '1056px'
    }
    mobileCheck(){
        // if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
        //     return false;
        // }else{
        //     return true;
        // }
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initBreadcrumbs(){
        let t = this;
        document.addEventListener("DOMContentLoaded",function(){
            let breadcrumbs = Util.$('.js-breadcrumbs');
            let breadcrumbLinks = Util.$('.js-breadcrumbs__list-item-link', breadcrumbs[0]);
            if(breadcrumbs.length > 0){
                t.configureLinks(breadcrumbs, breadcrumbLinks)
            }            
        })
        
    }
    configureLinks(breadcrumbs, links){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let t = this;
        let shrunkLinks = 0;
        let mobile = t.mobileCheck();
        if(t.mobileCheck()){
            if(links.length > 3){
                shrunkLinks = links.length - 3;
            }
        }else{
            if(links.length > 4){
                shrunkLinks = links.length - 4;
            }
            
        }
        if(shrunkLinks > 0){
            for(let step = 0; step < shrunkLinks; step++ ){
                let index = step + 1;
                links[index].classList.add('-abbreviated');
                links[index].addEventListener('click', function(ev){
                    if(!t.mobileCheck()){
                        ev.preventDefault();
                        //links[index].classList.remove('-abbreviated');
                        console.log(shrunkLinks);
                        links.forEach(function(el){
                            el.classList.remove('-abbreviated');
                        });
                    }
                });
            }
        }
    }    
}
export default Breadcrumbs;