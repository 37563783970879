import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class MountainReportDropdown {
    constructor() {
        this.initMountainReportDropdown();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initMountainReportDropdown(){
        let t = this;  
        document.addEventListener("DOMContentLoaded",function(){
            let header = Util.$('.js-header');
            let weatherButton = Util.$('.js-header__utility-weather-toggle', header[0]);
            let mountainConditionDropdown = Util.$('.js-mountain-report-dropdown' , header[0]);
            let dropdownClose = Util.$('.mountain-report-dropdown__close-btn' , header[0]);
            if(mountainConditionDropdown.length > 0 && weatherButton.length > 0){
                t.bindDropdownToggle(mountainConditionDropdown[0], weatherButton[0], header[0], '-mountain-report-open', dropdownClose[0]);
            }
        })
        
    }
    bindDropdownToggle(dropdown, item, parent, className, closeBtn){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            let active = item.classList.contains('-active');
            let body = document.getElementsByTagName( 'body' )[0];
            let menuActive = body.classList.contains('menu-open');
            let searchActive = body.classList.contains('search-open');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                parent.classList.remove(className);
                body.classList.remove('weather-open');
                h.hideDropdown(dropdown);
                if(menuActive){
                    setTimeout(function(){
                        parent.classList.add('-main-nav-open');
                    }, 350);
                }
                if(searchActive){
                    window.setTimeout(350, function(){
                        parent.classList.add('-search-open');
                    });
                }
            }else{
                if(menuActive){
                    parent.classList.remove('-main-nav-open');
                }
                if(searchActive){
                    parent.classList.remove('-search-open');
                }
                item.classList.add('-active');
                dropdown.classList.add('-active');
                parent.classList.add(className);
                body.classList.add('weather-open');
                h.showDropdown(dropdown);

                let delayedWebcams = document.querySelectorAll('.webcam__delay-load');
                if (delayedWebcams && delayedWebcams.length > 0) {
                    delayedWebcams.forEach(delayedWebcam => {
                        let elementId = delayedWebcam.getAttribute('id'), 
                            webcamId = delayedWebcam.getAttribute('data-webcamid');

                        if (elementId && webcamId) {
                            HDRelay.create({ target: elementId, id:  webcamId });
                            setTimeout( () => {
                                const hdrelayPlayer = delayedWebcam.shadowRoot.querySelector('.hdrelay-default-player');
                                if (hdrelayPlayer) {
                                    hdrelayPlayer.insertAdjacentHTML('beforeend', '<style>' +
                                        'div.hdrelay-default-player { border: 0; min-width: 100% }' +
										'.hdrelay-default-bigbt { display: none !important }' +
                                        '.hdrelay-default-bar { display: none !important }' +
                                    '</style>');
                                    delayedWebcam.classList.remove('webcam__delay-load');
                                }
                            }, 2000); // Is there an event when the HDRelay load is complete? */
                        }
                    });
                }
            }
                      
        });
        closeBtn.addEventListener('click', function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            item.click();
        })
    }
    showDropdown(dropdown){
        gsap.to(dropdown, {height: '100vh'});
    }
    hideDropdown(dropdown){
        gsap.to(dropdown, {height: '0vh'});
    }
    
}
export default MountainReportDropdown;