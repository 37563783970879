import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Header {
    constructor() {
        this.initHeader();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initHeader(){
        let t = this;
        document.addEventListener("DOMContentLoaded",function(){
            let header = Util.$('.js-header');
            let hero = Util.$('.js-hero');
            let homepageHero = Util.$('.js-homepage-hero');
            let menuButton = Util.$('.js-header__menu-btn', header[0]);
            let mainNav = Util.$('.js-main-nav' , header[0]);
            let booknowButton = Util.$('.js-header__utility-booknow', header[0]);
            let booknowDropdown = Util.$('.js-header__utility-booknow-dropdown' , header[0]);
            let booknowDropdownContent = Util.$('.js-header__utility-booknow-dropdown-wrapper' , header[0]);

            
            
            hero.forEach(function(el){
                let heroImg = Util.$('.js-hero__img', el);
                let heroVid = Util.$('.js-hero__video', el);
                if(heroImg.length > 0 || heroVid.length > 0){
                    header[0].classList.add('-has-hero');
                }
            });
            
            if(homepageHero.length > 0){
                header[0].classList.add('-has-homepage','-has-hero');
            }
            if(menuButton.length > 0){
                t.bindDropdownToggle(mainNav[0], menuButton[0], header[0], '-main-nav-open');
            }
            if(booknowDropdown.length > 0){
                t.bindDesktopDropdownToggle(booknowDropdown[0],booknowButton[0], booknowDropdownContent[0]);
            }
            t.scrollBehavior(header[0]);
        })
        
    }
    bindDropdownToggle(dropdown, item, parent, className){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            let active = item.classList.contains('-active');
            let body = document.getElementsByTagName( 'body' )[0];
            let searchActive = body.classList.contains('search-open');
            let weatherActive = body.classList.contains('weather-open');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                parent.classList.remove(className);
                body.classList.remove('menu-open');
                h.hideDropdown(dropdown);
                if(searchActive){
                    window.setTimeout(350, function(){
                        parent.classList.add('-search-open');
                    });
                }
                if(weatherActive){
                    setTimeout(function(){
                        parent.classList.add('-mountain-report-open');
                    }, 350);
                }
            }else{
                if(searchActive){
                    parent.classList.remove('-search-open');
                }
                if(weatherActive){
                    parent.classList.remove('-mountain-report-open');
                }
                item.classList.add('-active');
                dropdown.classList.add('-active');
                parent.classList.add(className);
                body.classList.add('menu-open');
                h.showDropdown(dropdown);
            }
                      
        });
    }
    bindDesktopDropdownToggle(dropdown, item, content){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        window.addEventListener('click', function(ev){
            let active = item.classList.contains('-active');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                h.hideDesktopDropdown(dropdown);
            }
        });
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            let active = item.classList.contains('-active');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                h.hideDesktopDropdown(dropdown);
            }else{
                item.classList.add('-active');
                dropdown.classList.add('-active');
                let height = content.offsetHeight;
                h.showDesktopDropdown(dropdown, height);
            }
                      
        });
    }
    showDesktopDropdown(dropdown, height){
        let tween = {height: ''};
            tween.height = height;
            gsap.to(dropdown, tween);
    }
    hideDesktopDropdown(dropdown){
        gsap.to(dropdown, {height: '0px'});
    }
    showDropdown(dropdown){
        gsap.to(dropdown, {height: '100vh'});
    }
    hideDropdown(dropdown){
        gsap.to(dropdown, {height: '0vh'});
    }
    scrollBehavior(header){
        // ADDING SCROLL DETECTION WITH REQUEST ANIMATION FRAME TO CHILL IT OUT
        let latestKnownScrollY = 0,
            scrollCountStart = 0,
            scrollDist = 0,
            ticking = false;

        const update = () => {
            // reset the tick so we can
            // capture the next onScroll
            ticking = false;
        }

        const onScroll = (ev) => {
            let prevScroll = latestKnownScrollY;
            if(window.scrollY){
                latestKnownScrollY = window.scrollY;
            }else{
                latestKnownScrollY = window.pageYOffset
            }
            if(latestKnownScrollY == 0){
                header.classList.remove('-scrollup');
                header.classList.remove('-scrolled');
                header.classList.remove('-scroll-hide');
                scrollCountStart = 0;
            }else if(prevScroll > latestKnownScrollY){
                header.classList.add('-scrollup');
                scrollDist = scrollDist + (latestKnownScrollY - prevScroll);
                scrollCountStart = scrollDist;
            }else{
                header.classList.add('-scrolled');
                scrollDist = scrollDist + (latestKnownScrollY - prevScroll);
                if(scrollDist > scrollCountStart + 300){
                    header.classList.add('-scroll-hide');
                    header.classList.remove('-scrollup');
                    scrollCountStart = scrollDist;
                }
            }
            requestTick();
        }

        const requestTick = () => {
            if(!ticking) {
                requestAnimationFrame(update);
            }
            ticking = true;
        }

        window.addEventListener('scroll', onScroll, false);
    }
}
export default Header;