import Util from "../utils/util.js";

class VisitInfo {
    constructor() {
        this.initVisitInfo();
    }
    initVisitInfo(){
        const visitInfoProgress = Util.$('.js-visit-info__progress');
        if(visitInfoProgress.length> 0){
           const totalProgress =  +visitInfoProgress[0].getAttribute('data-progress_range');
           const markers =  Util.$('.js-visit-info__progress-marker', visitInfoProgress[0]);
           const progressBar =  Util.$('.js-visit-info__progress-bar-indicator', visitInfoProgress[0]);
           const progressNum =  +progressBar[0].getAttribute('data-progress_current');
           const progressPercent = (progressNum/totalProgress)*100;
           
           progressBar[0].style.width = progressPercent +'%';
           markers.forEach(function(el){
                const milestone = +el.getAttribute('data-progress_milestone');
                const milestoneLocation = (milestone/totalProgress)*100;
                el.style.left = milestoneLocation +'%';
           });
        }
        
    }
}
export default VisitInfo;