class Tabs {
    constructor() {
        this.initTabs();
    }
    initTabs(){
        const ctrlSets = document.querySelectorAll('.js-ctrls-tabs');

        if (ctrlSets.length > 0) {

            for (let ctrlSet of ctrlSets) {
                const ctrls = ctrlSet.querySelectorAll('.js-ctrl-tab-btn');

                for (let ctrl of ctrls) {
                    const tabName = ctrl.getAttribute('data-tab');
                    const tab = document.querySelector('.js-tab[data-tab="' + tabName + '"]');
                    var tabSiblings = n => [...n.parentElement.children].filter(c=>c!=n);
                    var ctrlSiblings = n => [...n.parentElement.parentElement.children].filter(c=>c!=n);
                    
                    ctrl.addEventListener('click', () => {
    
                        for (let tabSibling of tabSiblings(tab)) {
                            tabSibling.classList.remove('-active');
                        }
                        for (let ctrlSibling of ctrlSiblings(ctrl)) {
                            ctrlSibling.querySelector('.js-ctrl-btn').classList.remove('-active');
                        }
                        tab.classList.add('-active');
                        ctrl.classList.add('-active');
                    });
                }
                document.addEventListener("DOMContentLoaded", () => {
                    ctrls[0].click();
                });
            }
        }
    }
}
export default Tabs;