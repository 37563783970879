import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Accordion {
    constructor() {
        this.initAccordion();
    }
    settings = {
        desktop: '1056px',
        toggleClass: '-item-active'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initAccordion(){
        let accordions = Util.$('.js-accordion');
        let a = this;
        if(accordions.length > 0){
            accordions.forEach(function(el){
                let toggle = Util.$('.js-accordion__toggle', el)[0];
                let content = Util.$('.js-accordion__content', el)[0];
                let container = Util.$('.js-accordion__content-container', el)[0];
                a.bindAccordionToggle(el, toggle, content, container);
            });
        }
        
        //this.bindDropdownToggle(mainNav[0], menuButton[0], header[0], '-main-nav-open');
    }
    bindAccordionToggle(accordion, toggle, content, container){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        
        
        toggle.addEventListener('click', function(ev){
            ev.preventDefault();
            let active = accordion.classList.contains('-open');
            
            if(active){
                toggle.classList.remove('-active');
                
                h.hideAccordion(accordion, content);
            }else{
                let height = container.offsetHeight;;
                toggle.classList.add('-active');
                accordion.classList.add('-active');
                h.showAccordion(accordion, content, height);
            }
                      
        });
    }
    showAccordion(accordion, container, height){
        let tween = {height: ''};
        tween.height = height;
        tween.onComplete = function(accordion){
            console.log('CALLBACK');
            console.log(accordion);
            accordion.classList.add('-open');
        };
        tween.onCompleteParams = [accordion];
        gsap.to(container, tween);
    }
    hideAccordion(accordion, container){
        let tween = {height: '0px'};
        tween.onComplete = function(accordion){
            console.log('CALLBACK');
            console.log(accordion);
            accordion.classList.remove('-open');
        };
        tween.onCompleteParams = [accordion];
        gsap.to(container, tween);
    }   
}
export default Accordion;