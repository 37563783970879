import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class LocalNav {
    constructor() {
        this.initLocalNav();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    resetDesktopActive(h){
        let activeNavItems = Util.$('.js-local-nav__link.-item-active');
        if(!h.mobileCheck()){
            activeNavItems.forEach(function(el){
                el.classList.remove('-item-active');
            });

            let activeDropdowns = Util.$('.js-local-nav__dropdown.-active');
            activeDropdowns.forEach(function(el){
                el.classList.remove('-active');
                h.hideDropdown(el);
            });
        }
    }
    setMobileActive(h){
        let activeNavItems = Util.$('.js-local-nav__link.-item-active');
        if (h.mobileCheck() && activeNavItems.length > 0) {
            let mobileToggle = Util.$('.js-local-nav__mobile-toggle');
            if(mobileToggle[0]){
                mobileToggle[0].classList.add('-item-active');
            }
        }
    }
    initLocalNav(){
        let t = this;
        document.addEventListener("DOMContentLoaded",function(){
            let localNav = Util.$('.js-local-nav');
            let localNavMobileDropdown = Util.$(".js-local-nav__wrapper", localNav[0]);
            let localNavMobileDropdownContent = Util.$(".js-local-nav__slider", localNav[0]);
            let mobileToggle = Util.$('.js-local-nav__mobile-toggle', localNav[0]);
            let navItems = Util.$('.js-local-nav__item', localNav[0]);

            window.addEventListener("resize", function(){
                t.resetDesktopActive(t);
                t.setMobileActive(t);
            });

            if(localNav.length > 0){
                t.bindDropdownToggle(localNavMobileDropdown[0], mobileToggle[0], localNavMobileDropdownContent[0]);
            }
            if(navItems.length > 0){
                let navContainerOffsetEnd = localNavMobileDropdownContent[0].offsetLeft + localNavMobileDropdownContent[0].offsetWidth;
                let lastItemOffset = navItems[navItems.length - 1].offsetLeft + navItems[navItems.length - 1].offsetWidth;
                if(navContainerOffsetEnd + 20 < lastItemOffset){
                    localNav[0].classList.remove('-desktop');
                }
                navItems.forEach(function(el){
                    let localNavLink = Util.$('.js-local-nav__link', el);
                    let localNavDropdown = Util.$('.js-local-nav__dropdown', el);
                    let localNavDropdownContent = Util.$('.js-local-nav__dropdown-wrapper', el);
                    if(localNavLink.length > 0 && localNavDropdown.length > 0 && localNavDropdownContent.length > 0){
                        t.bindSubnavToggle(localNavDropdown[0], localNavLink[0], localNavDropdownContent[0], localNavMobileDropdown[0]);
                    }
                });
            }
            
        })
        
    }
    
    bindDropdownToggle(dropdown, item, content){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        window.addEventListener('click', function(ev){
            // THE BELOW WAS EXISTING CODE AS OF 07/18/2023 - LEFT IN FOR JIM REVIEW IF STILL NECESSARY OR NOT
            // let active = item.classList.contains('-item-active');
            // if(active){
            //     item.classList.remove('-item-active');
            //     dropdown.classList.remove('-active');
            //     h.hideDropdown(dropdown);
            // }

            h.resetDesktopActive(h);
        });
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            let active = item.classList.contains('-item-active');
            if(active){
                item.classList.remove('-item-active');
                dropdown.classList.remove('-active');
                h.hideDropdown(dropdown);
                let activeKids = Util.$('.js-local-nav__link.-item-active', dropdown);
                console.log('CLOSE NAV');
                console.log(activeKids);
                if(activeKids.length > 0){
                    console.log('GOT ACTIVE KIDS');
                    activeKids.forEach(function(el){
                        
                        setTimeout(function(){
                            el.click();
                        }, 500);
                    });
                }
                
            }else{
                item.classList.add('-item-active');
                dropdown.classList.add('-active');
                let height = content.offsetHeight;
                h.showDropdown(dropdown, height);
            }
                      
        });
    }

    bindSubnavToggle(dropdown, item, content, parentNav){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            ev.stopPropagation();

            let active = item.classList.contains('-item-active');

            h.resetDesktopActive(h);

            if(active){
                item.classList.remove('-item-active');
                dropdown.classList.remove('-active');
                h.hideDropdown(dropdown);
            }else{
                item.classList.add('-item-active');
                dropdown.classList.add('-active');
                parentNav.style.setProperty('height', 'auto');
                let height = content.offsetHeight;
                h.showDropdown(dropdown, height);
            }
                      
        });
    }
    showDropdown(dropdown, height){
        let tween = {height: ''};
            tween.height = height;
            gsap.to(dropdown, tween);
    }
    hideDropdown(dropdown){
        gsap.to(dropdown, {height: '0px'});
    }
    
}
export default LocalNav;