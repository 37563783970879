import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class MainNav {
    constructor() {
        this.initMainMenu();
    }
    settings = {
        desktop: '1056px',
        toggleClass: '-item-active'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initMainMenu(){
        let mainNav = Util.$('.js-main-nav');
        let toggleItem = Util.$('.js-main-nav__list-item');
        let header = Util.$('.js-header');
        let h = this;
        let s = this.settings;
        toggleItem.forEach(function(el){
            let thisLink = Util.$('.js-main-nav__list-item-link', el);
            let thisDropdown = Util.$('.js-main-nav__subnav', el);
            let thisPlus = Util.$('.main-nav__link-plus', el);
            if(thisPlus.length > 0){
                h.bindSubnavToggle(thisDropdown[0], thisLink[0], header[0], '-subnav-open', s.toggleClass);
            }
        });
        //this.seasonToggle(mainNav);
        //this.bindDropdownToggle(mainNav[0], menuButton[0], header[0], '-main-nav-open');
    }
    bindSubnavToggle(subnav, item, parent, className, toggleClass){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        const findSiblings = function(item){
            let theListitem = item.parentElement;
            let theList = item.parentElement.parentElement;
            let theSiblings = Util.$('.js-main-nav__list-item', theList);
            let siblings = [];
            theSiblings.forEach(function(el){
                if(el != theListitem){
                    siblings.push(el);
                }
            });
            return siblings;

        };
        const findActive = function(siblings){
            let activeMenuItem = {};
            activeMenuItem.active = false;
            siblings.forEach(function(el){
                let thisLink = Util.$('.js-main-nav__list-item-link', el);
                let thisMenu = Util.$('.js-main-nav__subnav', el);
                if(thisLink[0].classList.contains('-item-active')){
                    activeMenuItem.link = thisLink[0];
                    activeMenuItem.nav = thisMenu[0];
                    activeMenuItem.active = true;
                }
            });
            return activeMenuItem;
        };
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            let active = item.classList.contains(toggleClass);
            let siblings = findSiblings(item);
            
            let activeListItem  = findActive(siblings);
            if(active){
                item.classList.remove(toggleClass);
                subnav.classList.remove(toggleClass);
                parent.classList.remove(className);
                h.hideSubmenu(subnav);
            }else{
                let height = 0;
                if(h.mobileCheck()){
                    let innerItem = Util.$('.js-main-nav__subwrapper', subnav)[0];
                    height = innerItem.offsetHeight;
                }else{
                    if(activeListItem.active){
                        activeListItem.nav.classList.add('-layered');
                        setTimeout(function(){
                            activeListItem.nav.classList.remove('-item-active');
                            activeListItem.link.classList.remove('-item-active');
                            // activeListItem.nav.classList.remove('-layered');
                            // activeListItem.link.classlist.remove('-item-active');
                            activeListItem.nav.removeAttribute("style");
                          }, 500);
                        setTimeout(function(){
                            activeListItem.nav.classList.remove('-layered');
                          }, 1000);
                    }
                }

                item.classList.add(toggleClass);
                subnav.classList.add(toggleClass);
                parent.classList.add(className);
                h.showSubmenu(subnav, height);
            }
                      
        });
    }
    showSubmenu(dropdown, height){
        if(this.mobileCheck()){
            let tween = {height: ''};
            tween.height = height;
            gsap.to(dropdown, tween);
        }else{
            let currentActive = Util.$('.js-main-nav__subnav.-item-active');
            let currentActiveBtn = Util.$('.js-main-nav__list-item-link.-item-active');
            let tween = {width: '66%'};
            
            if(currentActive.length>0){
                tween.callback = function(){
                    //currentActiveBtn.click();
                }
            }
            gsap.to(dropdown, tween);
        }
    }
    hideSubmenu(dropdown){
        if(this.mobileCheck()){
            gsap.to(dropdown, {height: '0px'});
        }else{
            gsap.to(dropdown, {width: '0%'});
        }
        
    }   
}
export default MainNav;