import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Alerts {
    constructor() {
        this.initAlert();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initAlert(){
        let t = this;
        document.addEventListener("DOMContentLoaded",function(){
            let header = Util.$('.js-header');
            let alertButton = Util.$('.js-header__utility-alerts', header[0]);
            let alertDropdown = Util.$('.js-alert__dropdown' , header[0]);
            let alertDropdownContent = Util.$('.js-alert__dropdown-wrapper' , header[0]);
            let alerts = Util.$('.js-alert__dropdown-list-item', alertDropdown[0]);
            let clearAll = Util.$('.js-alert__dropdown-ctrl-clear-btn',alertDropdown[0] );
            if(alertDropdown.length > 0){
                t.bindDesktopDropdownToggle(alertDropdown[0],alertButton[0], alertDropdownContent[0]);
            }
            if(alerts.length > 0){
                let activeAlerts = alerts.length - 1;
                alerts.forEach(function(el){
                    let thisClear = Util.$('.js-alert__dropdown-item-dismiss', el)[0];
                    if(thisClear){
                        thisClear.addEventListener('click', function(ev){
                            ev.preventDefault();
                            ev.stopPropagation();
                            let height = el.offsetHeight;
                            el.style.setProperty('height', height + 'px');
                            el.classList.add('-dismissed');
                            gsap.to(el, {height: '0px'});
                            alertDropdown[0].style.removeProperty('height');
                            activeAlerts = activeAlerts - 1;
                            
                            if(activeAlerts === 0){
                                alerts[alerts.length - 1].classList.remove('-not-needed');
                                alerts[alerts.length - 1].style.removeProperty('height');
                                clearAll[0].classList.add('-hidden');
                                alertButton[0].classList.remove('-has-alerts');
                            }
                        });
                    }
                });
                if(alerts.length === 1){
                    alerts[0].classList.remove('-not-needed');
                    alerts[0].style.removeProperty('height');
                    clearAll[0].classList.add('-hidden');
                }
                if(alerts.length > 1){
                    alertButton[0].classList.add('-has-alerts');
                }
                clearAll[0].addEventListener('click', function(ev){
                    ev.preventDefault();
                    ev.stopPropagation();
                    let clearBtns = Util.$('.js-alert__dropdown-item-dismiss', alertDropdown[0]);
                    clearBtns.forEach(function(el){
                        el.click();
                    });
                    alertButton[0].classList.remove('-has-alerts');
                });
            }
        })
        
    }

    bindDesktopDropdownToggle(dropdown, item, content){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        window.addEventListener('click', function(ev){
            let active = item.classList.contains('-active');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                h.hideDesktopDropdown(dropdown);
            }
        });
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            let active = item.classList.contains('-active');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                h.hideDesktopDropdown(dropdown);
            }else{
                item.classList.add('-active');
                dropdown.classList.add('-active');
                let height = content.offsetHeight;
                h.showDesktopDropdown(dropdown, height);
            }
                      
        });
    }
    showDesktopDropdown(dropdown, height){
        let tween = {height: ''};
            tween.height = height;
            gsap.to(dropdown, tween);
    }
    hideDesktopDropdown(dropdown){
        gsap.to(dropdown, {height: '0px'});
    }

}
export default Alerts;