import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Search {
    constructor() {
        this.initSearch();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initSearch(){
        let t = this;
        document.addEventListener("DOMContentLoaded",function(){
            let header = Util.$('.js-header');
            let searchButton = Util.$('.js-header__utility-search', header[0]);
            let searchDropdown = Util.$('.js-search' , header[0]);
            let searchDropdownClose = Util.$('.js-search__close' , header[0]);
            if(searchDropdown.length > 0 && searchButton.length > 0){
                t.bindDropdownToggle(searchDropdown[0], searchButton[0], header[0], '-search-open', searchDropdownClose[0]);
            }
        })
        
    }
    bindDropdownToggle(dropdown, item, parent, className, closeBtn){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            let active = item.classList.contains('-active');
            let body = document.getElementsByTagName( 'body' )[0];
            let menuActive = body.classList.contains('menu-open');
            let weatherActive = body.classList.contains('weather-open');
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                parent.classList.remove(className);
                body.classList.remove('search-open');
                h.hideDropdown(dropdown);
                if(menuActive){
                    setTimeout(function(){
                        parent.classList.add('-main-nav-open');
                    }, 350);
                }
                if(weatherActive){
                    setTimeout(function(){
                        parent.classList.add('-mountain-report-open');
                    }, 350);
                }
            }else{
                if(menuActive){
                    parent.classList.remove('-main-nav-open');
                }
                if(weatherActive){
                    parent.classList.remove('-mountain-report-open');
                }
                item.classList.add('-active');
                dropdown.classList.add('-active');
                parent.classList.add(className);
                body.classList.add('search-open');
                h.showDropdown(dropdown);
            }
                      
        });
        closeBtn.addEventListener('click', function(ev){
            ev.preventDefault();
            ev.stopPropagation();
            item.click();
        })
    }
    showDropdown(dropdown){
        gsap.to(dropdown, {height: '100vh'});
    }
    hideDropdown(dropdown){
        gsap.to(dropdown, {height: '0vh'});
    }
    
}
export default Search;