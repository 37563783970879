import * as fecha from "fecha";
import HotelDatepicker from "hotel-datepicker";
import Shuffle from 'shufflejs';
import GLightbox from 'glightbox';

class Filters {
    constructor() {
        this.initFilters();
    }
    initFilters(){
        let filterInit = false;
        const filterBtn = document.querySelector('.js-filters__lightbox-trigger');
        const f = this;
        if(filterBtn){
            filterBtn.addEventListener('click', function(ev){
                if(!filterInit){
                    f.activateFilters();
                    filterInit = true;
                    filterBtn.click();
                }
            });
        }
    }
    activateFilters(){
        // Check for result groups
        const f = this;
        const resultGroups = document.querySelectorAll('.js-shuffle__results');
        if (resultGroups.length > 0) {

            // For each result group
            for (let results of resultGroups) {
                const resultsId = results.getAttribute('id');

                // Check for load more functionality
                // TODO: Remove all load more checks
                let loadMore = false;

                // Check for filters
                const filters = document.querySelector('.js-filters[data-results="' + resultsId + '"]');
                const items = results.querySelectorAll('.js-shuffle__result');
                
                // Set equal height to results
                function setEqualRowHeight(list) {
                    // TODO: Make responsive somehow to screen resize
                    if (window.matchMedia('all and (min-width: 768px)').matches) {
                        let largestHeight = 0;
                        for (let item of list) {
                            if (item.offsetHeight > largestHeight)
                                largestHeight = item.offsetHeight;
                            
                        }
                        for (let item of list) {
                            item.setAttribute('style', 'height:' + largestHeight + 'px');
                        }
                    }
                }
                setEqualRowHeight(items);
                
                // Check for results counter
                const resultsCounter = results.parentElement.querySelector('.js-shuffle__count');

                // Count functionality
                function updateCount(filtersCount, resultsCount, totalCount, filtersCounter, resultsCounter, resultsText, loadMore, reset) {
                    resultsText = document.querySelector('.js-shuffle__no-results-text');
                    if (filtersCount && filtersCounter) {
                        filtersCounter.innerHTML = filtersCount;
                    }
                    if (resultsCount >= 0 && resultsText) {
                        const counterTotal = resultsCounter.querySelector('.js-shuffle__count-total');
                        const counterVisible = resultsCounter.querySelector('.js-shuffle__count-visible');
                        resultsCounter.classList.add('-active');
                        if (counterTotal)
                            counterTotal.innerHTML = totalCount;
                        if (counterVisible)
                            counterVisible.innerHTML = resultsCount;

                        const noResultsText = resultsText.nextElementSibling;
                        const loadMoreBtn = null;

                        if (resultsCount == 0 && !reset) {
                            if (resultsText) {
                                resultsText.classList.add('-active');
                            }
                        } else {
                            if (loadMoreBtn) {
                                loadMoreBtn.removeAttribute('data-reset');
                                reset = null;
                            }
                            if(resultsText){
                                resultsText.classList.remove('-active');
                            }
                            
                            
                        }
                    }
                }

                // Init shuffle
                
                // TODO: Ditch shuffle
                let shuffleInstance = new Shuffle(document.getElementById(resultsId), {
                    itemSelector: '.js-shuffle__result',
                    columnThreshold: 1,
                    delimiter: ',',
                    sizer: '.js-shuffle__sizer',
                    filterMode: 'all'
                });
                
                // Check for filters
                if (filters) {
                    
                    let selectedFilters = [];
                    let selectedDateRange = '';
                    let submittedOnce = false;
                    let filterDateRange = [];
                    let hasTag = false;
                    let datesInRange = false;
                    const resultsText = results.querySelectorAll('.js-shuffle__no-results-text');

                    // Programtically trigger select changes
                    function triggerChange(element) {
                        let changeEvent = new Event('change');
                        element.dispatchEvent(changeEvent);
                    }

                    // Custom select functionality
                    function initSelects(selects) {
                        
                        for (let select of selects) {
                            
                            const native = select.querySelector('.js-select__native');

                            if (native) {

                                native.onclick = () => {
                    
                                    for (let select of selects) {  
                                        select.classList.remove('-open');
                                        select.querySelector('.js-select__native').classList.remove('-focused');
                                        native.blur();
                                    }
                                    select.classList.add('-open');
                                    native.classList.add('-focused');
                                };
                                native.onchange = () => {
                                    select.classList.remove('-open');
                                    native.classList.remove('-focused');
                                };

                                const custom = select.querySelector('.js-select__custom');
                                const customOptions = custom.querySelectorAll('a');

                                if (customOptions.length > 0) {

                                    for (let customOption of customOptions) {

                                        if (!customOption.classList.contains('select__faux')) {
                                            customOption.onclick = (event) => {
                                                event.preventDefault();
                                                const group = customOption.getAttribute('data-group');
                                                const text = customOption.innerHTML;
                                                native.value = group;
                                                triggerChange(native);
                                                custom.querySelector('li:first-child a').innerHTML = text;
                                            };
                                        }
                                        else {
                                            customOption.onclick = (event) => {
                                                event.preventDefault();
                                                select.classList.remove('-open');
                                                native.classList.remove('-focused');
                                            };
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Datepicker settings
                    function initDatepickers(datepickers) {

                        for (let datepicker of datepickers) {
                            datepicker = document.getElementById(datepicker.getAttribute('id'));
                            new HotelDatepicker(datepicker, {
                                inline: true,
                                moveBothMonths: true
                                // clearButton: true,
                                // submitButton: true,
                                // topbarPosition: 'bottom'
                            });
                        }
                    }

                    // Date ranges within date ranges...
                    function getDatesInRange(startDate, endDate) {
                        const date = new Date(startDate.getTime());
                        const dates = [];
                    
                        while (date <= endDate) {
                        dates.push(new Date(date));
                        date.setDate(date.getDate() + 1);
                        }
                        return dates;
                    }

                    // Filtering for events
                    function inDateRange(filterTags, resultTags, resultStartDate, resultEndDate, filterStartDate, filterEndDate) {
                        hasTag = false;
                        datesInRange = false;
                        let tagsAllMatch = false;
                        let matchCount = 0;

                        if (filterTags && resultTags) {
                            for (let filterTag of filterTags) {
                                for (let resultTag of resultTags.split(',')) {
                                    if (filterTag == resultTag) {
                                        hasTag = true;
                                        matchCount++;
                                        break;
                                    }
                                }
                            }
                        }
                        if(matchCount == filterTags.length){
                            tagsAllMatch = true;
                        }
                        if (filterStartDate && filterEndDate) {
                            const resultDateRange = getDatesInRange(resultStartDate, resultEndDate);
                            filterDateRange = getDatesInRange(filterStartDate, filterEndDate);

                            for (let resultDate of resultDateRange) {
                                resultDate = resultDate.toISOString().split('T')[0];

                                for (let filterDate of filterDateRange) {
                                    filterDate = filterDate.toISOString().split('T')[0];

                                    if (resultDate == filterDate)
                                        datesInRange = true;
                                }
                            }
                        }

                        // LOGIC FOR EVENTS
                        if (tagsAllMatch && datesInRange)
                            return true;
                        if (tagsAllMatch && !filterStartDate && !filterEndDate)
                            return true;
                        if (!filterTags.length > 0 && datesInRange)
                            return true;
                        if (!filterTags.length > 0 && !filterStartDate && !filterEndDate)
                            return true;

                    }

                    // Reset filters functionality
                    function resetFilters(selects, checkboxes, radios, datepickers, shuffleInstance, filtersCounter, resultsCounter, resultsText, resultsList) {

                        if (selects.length > 0) {
                            for (let select of selects) {
                                let selectNative = select.querySelector('.js-select__native');
                                selectNative.value = '';
                                triggerChange(selectNative);
                            }
                        }
                        if (checkboxes.length > 0) {
                            for (let checkbox of checkboxes) {
                                if (checkbox.hasAttribute('checked')) 
                                    checkbox.checked = true;
                                else
                                    checkbox.checked = false;
                            }
                        }
                        if (radios.length > 0) {
                            for (let radio of radios) {
                                if (radio.hasAttribute('checked')) 
                                    radio.checked = true;
                                else
                                    radio.checked = false;
                            }
                        }
                        if (datepickers.length > 0) {
                            for (let datepicker of datepickers) {
                                const parent = datepicker.parentElement;
                                const daySelected = parent.querySelector('.datepicker__month-day--first-day-selected');

                                if (daySelected) {
                                    parent.querySelector('.datepicker__month-day--first-day-selected').click();
                                    parent.querySelector('.datepicker__month-day--first-day-selected').click();
                                    datepicker.value = '';
                                }
                            }
                        }
                        // let resultsItems = resultsList.querySelectorAll('.js-shuffle__results');
                        // const loadMoreBtn = resultsList.parentElement.querySelector('.js-shuffle__btn');

                        shuffleInstance.filter();
                        updateCount(null, shuffleInstance.visibleItems, shuffleInstance.items.length, null, resultsCounter, resultsText, false, true);

                        selectedFilters = [];
                        selectedDateRange = '';
                        submittedOnce = false;
                        filterDateRange = [];
                    }

                    // Submit filters functionality
                    // TODO: Add radio
                    function submitFilters(submit, resets, selects, checkboxes, radios, datepickers, shuffleInstance, filtersCounter, resultsCounter, resultsText, filtersLightbox, results, loadMore) {
                        let i = 0;
                        let shuffleFilters = [];
                        if (selects.length > 0) {
                            for (let select of selects) {
                                const selectNative = select.querySelector('.js-select__native');

                                if (selectNative.value) {
                                    shuffleFilters.push(selectNative.value);
                                    i++;
                                }
                            }
                        }
                        if (checkboxes.length > 0) {
                            for (let checkbox of checkboxes) {
                                if (checkbox.checked) {
                                    shuffleFilters.push(checkbox.getAttribute('data-group'));
                                    i++;
                                }
                            }
                        }
                        if (radios.length > 0) {
                            for (let radio of radios) {
                                if (radio.checked) {
                                    shuffleFilters.push(radio.getAttribute('data-group'));
                                    i++;
                                }
                            }
                        }
                        // TODO: RADIO CHECK
                        let filterStartDate = '';
                        let filterEndDate = '';
                        if (datepickers.length > 0) {

                            for (let datepicker of datepickers) {
                                const value = datepicker.value;

                                if (value) {
                                    filterStartDate = new Date(value.split(' - ')[0]);
                                    filterStartDate.setDate(filterStartDate.getDate());
                                    filterEndDate = new Date(value.split(' - ')[1]);
                                    filterEndDate.setDate(filterEndDate.getDate());
                                    i++;
                                    selectedDateRange = value;
                                }
                            }
                        }
                        if (datepickers.length > 0) {
                            // IF THE LIGHTBOX HAS DATES
                            if (shuffleFilters.length > 0){
                                // FILTER WITH DATES AND OTHER OPTIONS
                                shuffleInstance.filter(
                                    (element) => inDateRange(
                                        shuffleFilters,
                                        element.dataset.groups,
                                        new Date(element.getAttribute('data-startDate')),
                                        new Date(element.getAttribute('data-endDate')),
                                        filterStartDate,
                                        filterEndDate
                                    )
                                );
                            }
                            else{
                                // FILTERS BEING APPLIED WITHOUT A SELECTION
                                shuffleInstance.filter(
                                    (element) => inDateRange(
                                        shuffleFilters,
                                        element.dataset.groups,
                                        new Date(element.getAttribute('data-startDate')),
                                        new Date(element.getAttribute('data-endDate')),
                                        filterStartDate,
                                        filterEndDate
                                    )
                                );
                            }
                                

                        } else {

                            if (!loadMore) {
                                Shuffle.FilterMode.ALL;
                            }
                            if (shuffleFilters.length > 0) {
                                shuffleInstance.filter(shuffleFilters);
                            } else {
                                shuffleInstance.filter();
                            }
                        }

                        let filteredResults = results.querySelectorAll('.shuffle-item--visible.-visible');
                        if (!loadMore) {
                            filteredResults = results.querySelectorAll('.shuffle-item--visible');
                        }

                        if (filteredResults.length > 0) {
                            updateCount(null, filteredResults.length, shuffleInstance.items.length, filtersCounter, resultsCounter, resultsText, true, false)
                        } else {
                            updateCount(0, 0, shuffleInstance.items.length, filtersCounter, resultsCounter, resultsText, true, false);
                        }

                        if (filtersLightbox) {
                            selectedFilters = shuffleFilters;

                            if (filtersCounter) {

                                if (i > 0) 
                                    filtersCounter.innerHTML = i
                                else
                                    filtersCounter.innerHTML = '';
                            }
                            if (selectedFilters.length == 0 && selectedDateRange == '') {

                                for (let reset of resets) {
                                    reset.click();
                                    break;
                                }
                                filtersLightbox.close();
                                
                            } else {

                                if (submittedOnce) {
                                    submit.click();
                                    filtersLightbox.close();

                                } else {

                                    filtersLightbox.close();
                                    submittedOnce = true;
                                }
                            }
                        }
                    }

                    // Activate filter controls for a set of results
                    function initFilterControls(filterGroup, shuffleInstance, filtersCounter, resultsCounter, resultsText, filtersLightbox, filterLightboxTrigger, results) {
                        
                        if (filterGroup) {
                            
                            let resets;

                            if (filtersLightbox) {

                                // Reset filters to newly generated markup
                                const lightbox = document.querySelector('.glightbox-container');
                                filterGroup = lightbox.querySelector('.js-filters');

                                // Add close functionality to newly generated markup
                                const close =  filterGroup.querySelector('.js-filters__btn-close');
                                if (close) {
                                    close.onclick = () => {   
                                        filtersLightbox.close();
                                    }
                                }
                                resets = document.querySelectorAll('.js-filters__btn-reset');
                            } else {
                                resets = document.querySelectorAll('.js-filters__btn-reset');
                            }

                            // Gather filter controls
                            // TODO: Add radios
                            const selects = filterGroup.querySelectorAll('.js-select__wrap');
                            const checkboxes = filterGroup.querySelectorAll('input[type="checkbox"]');
                            const radios = filterGroup.querySelectorAll('input[type="radio"]');
                            const datepickers = filterGroup.querySelectorAll('.js-filters__datepicker-input');
                            const submit = filterGroup.querySelector('.js-filters__btn-submit');
                            
                            // Fill in previous selections
                            if (selectedDateRange) {
                                for (let datepicker of datepickers) {
                                    datepicker.value = selectedDateRange;
                                }
                            }
                            if (selectedFilters.length > 0) {

                                for (let filter of selectedFilters) {
                                    
                                    for (let checkbox of checkboxes) {
                                        if (checkbox.getAttribute('data-group') === filter) {
                                            checkbox.checked = true;
                                        }
                                    }
                                    for (let radio of radios) {
                                        if (radio.getAttribute('data-group') === filter) {
                                            radio.checked = true;
                                        }
                                    }
                                    for (let select of selects) {
                                        const options = select.querySelectorAll('option');
                                        for (let option of options) {
                                            if (option.getAttribute('data-group') === filter) {
                                                option.selected = true;
                                                triggerChange(select);
                                            }
                                        }
                                    }
                                }
                            }

                            // Initiate all filter controls
                            if (selects.length > 0)
                                initSelects(selects);

                            if (datepickers.length > 0)
                                initDatepickers(datepickers);

                            if (resets.length > 0) {

                                for (let reset of resets) {
                                    reset.onclick = () => resetFilters(selects, checkboxes, radios, datepickers, shuffleInstance, filtersCounter, resultsCounter, resultsText, results);
                                }
                            }
                            if (submit){
                                const resultsText = results.querySelectorAll('.js-shuffle__no-results-text');   
                                submit.onclick = () => submitFilters(submit, resets, selects, checkboxes, radios, datepickers, shuffleInstance, filtersCounter, resultsCounter, resultsText, filtersLightbox, results, loadMore);
                            }
                                

                            // Update counters with visible count on page load
                            // window.addEventListener('load', () => {

                            //     if (filterLightboxTrigger)
                            //         updateCount(0, shuffleInstance.visibleItems, 10, filtersCounter, resultsCounter, null, true, false)
                            //     else if (resultsText)
                            //         updateCount(0, shuffleInstance.visibleItems, 10, filtersCounter, resultsCounter, resultsText, true, false);                
                            // });
                        }
                    }

                    // Check for lightbox content
                    const filtersLightbox = filters.parentElement;
                    if (filtersLightbox && filtersLightbox.classList.contains('filters__lightbox-content')) {

                        // Check for lightbox trigger
                        const filtersLightboxTrigger = filtersLightbox.previousElementSibling;
                        if (filtersLightboxTrigger && filtersLightboxTrigger.classList.contains('js-filters__lightbox-trigger')) {

                            // Initiate glightbox
                            const filtersLightboxContent = filtersLightbox.innerHTML;
                            const glightbox = GLightbox({
                                elements: [{'content': filtersLightboxContent}],
                                draggable: false,
                                zoomable: false,
                                width: 900,
                                height: 900,
                                skin: 'filters'
                            });

                            // Check for filters counter
                            const filtersCounter = filtersLightboxTrigger.parentElement.querySelector('js-filters__count');

                            // Initiate filters on lightbox open
                            glightbox.on('open', () => {
                                initFilterControls(filters, shuffleInstance, filtersCounter, resultsCounter, resultsCounter, glightbox, filtersLightboxTrigger, results);
                            });
                            filtersLightboxTrigger.onclick = () => {
                                glightbox.open();
                            }

                            // Remove original markup so there are no duplicate IDs and inputs in the DOM
                            filtersLightbox.remove();
                        }
                    } else {

                        // Initiate on-page filters
                        //initFilterControls(filters, shuffleInstance, null, resultsCounter, resultsCounter, null, null, results);
                    }

                }
            }// End loop of result group
        }
    }
}
export default Filters;