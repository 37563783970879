import Util from "../utils/util.js";
import lightGallery from 'lightgallery';

// Plugins
import lgZoom from 'lightgallery/plugins/zoom'
class TrailMap {
    constructor() {
        this.initTrailMap();
    }
    initTrailMap(){
        let trailMaps = Util.$('.js-trail-map');
        if(trailMaps.length > 0){
            trailMaps.forEach(function(el){
                lightGallery(el, {
                    speed: 500,
                    licenseKey: 'C94E7EDA-1939-4C9D-A24B-8E652BE5EFBD',
                    plugins: [lgZoom],
                    download: false,
                    selector: '.js-trail-map__lightbox-link'
                  });
            });
            
        }
        
    }
}

export default TrailMap;