class LoadMore {
    constructor() {
        this.initLoadMore();
    }
    initLoadMore(){

        // Check for result groups
        const resultGroups = document.querySelectorAll('.js-shuffle__load-more');
        if (resultGroups.length > 0) {

            // For each result group
            for (let results of resultGroups) {
                const resultsId = results.getAttribute('id');

                // Check for results counter
                const resultsCounter = results.parentElement.querySelector('.js-shuffle__count');
                const shuffleInstance = null;
                let lastLoadMoreCount = 0;

                // Count functionality
                function updateCount(filtersCount, resultsCount, totalCount, filtersCounter, resultsCounter, resultsText, loadMore, reset) {

                    if (filtersCount && filtersCounter) {
                        filtersCounter.innerHTML = filtersCount;
                    }
                    if (resultsCount >= 0 && resultsText) {
                        
                        const counterTotal = resultsCounter.querySelector('.js-shuffle__count-total');
                        const counterVisible = resultsCounter.querySelector('.js-shuffle__count-visible');

                        if (counterTotal)
                            counterTotal.innerHTML = totalCount;
                        if (counterVisible)
                            counterVisible.innerHTML = resultsCount;

                    }
                }

                const items = results.querySelectorAll('.js-shuffle__result');
                const loadMoreBtn = results.parentElement.querySelector('.js-shuffle__btn');
                let itemsLoaded = 0;

                // Load more functionality
                function loadMore(perPage, page, results, shuffleInstance, reset) {
                    const resultsId = results.getAttribute('id');
                    // const filters = document.querySelector('[data-results="' + resultsId + '"]');
                    const items = results.querySelectorAll('.js-shuffle__result');
                    const loadMoreBtn = results.parentElement.querySelector('.js-shuffle__btn');
                    const loadMoreCount = results.parentElement.querySelector('.js-shuffle__count');
                    perPage = Number(perPage);
                    page = Number(page);
                    itemsLoaded = 0;
                    let visibleItems = results.querySelectorAll('.js-shuffle__result.-visible');
                    for (let item of items) {
                        if (itemsLoaded < (perPage * page)) {
                            item.classList.remove('-hidden');
                            item.classList.add('-visible');
                            itemsLoaded++;
                        } else {
                            break;
                        }
                    }

                    updateCount(null, itemsLoaded, items.length, null, loadMoreCount, loadMoreCount, true, false);

                    if (items.length == itemsLoaded) {
                        loadMoreBtn.classList.remove('-active');
                    }

                    lastLoadMoreCount = itemsLoaded + perPage;
                    loadMoreBtn.blur();

                }

                // Bind load more
                let page = results.getAttribute('data-page');
                let perPage = results.getAttribute('data-per-page');
                const loadMoreCount = results.parentElement.querySelector('.js-shuffle__count');
                updateCount(null, 3, items.length, null, loadMoreCount, loadMoreCount, true, false);
                loadMoreBtn.addEventListener('click', () => {
                    page++;
                    loadMore(perPage, page, results, shuffleInstance);
                    results.setAttribute('data-page', page);
                });
                results.setAttribute('data-page', page);
                
                
            }// End loop of result group
        }
    }
}
export default LoadMore;