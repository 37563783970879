import Shuffle from 'shufflejs';

class Weddings {
    constructor() {
        this.initWeddings();
    }
    initWeddings(){

        // Check for result groups
        const resultGroups = document.querySelectorAll('.js-shuffle__results');
        // TODO: clean up this conditional
        if (resultGroups.length > 0 && resultGroups[0].classList.contains('weddings__result-list')) {
            // For each result group
            for (let results of resultGroups) {
                const resultsId = results.getAttribute('id');

                // TODO: remove load more from weddings entirely
                let loadMore = false;

                // Check for filters
                const filters = document.querySelector('.js-filters[data-results="' + resultsId + '"]');

                // Check for results counter
                const resultsCounter = results.parentElement.querySelector('.js-shuffle__count');
                let lastLoadMoreCount = 0;

                // Count functionality
                function updateCount(filtersCount, resultsCount, filtersCounter, resultsCounter, resultsText, loadMore, reset) {
                    if (filtersCount && filtersCounter) {
                        filtersCounter.innerHTML = filtersCount;
                    }
                    if (resultsCount >= 0 && resultsText) {
                        const counterTotal = resultsCounter.querySelector('.js-shuffle__count-total');
                        const counterVisible = resultsCounter.querySelector('.js-shuffle__count-visible');
                        if (counterTotal)
                            counterTotal.innerHTML = resultsCount;
                        if (counterVisible)
                            counterVisible.innerHTML = resultsCount;

                        const noResultsText = resultsText.nextElementSibling;
                        const loadMoreBtn = resultsText.parentElement.querySelector('.js-shuffle__btn');

                        if (resultsCount == 0 && !reset) {

                            if (loadMoreBtn) {
                                loadMoreBtn.click();
                            } else {
                                resultsText.classList.remove('-active');
                                if (noResultsText)
                                    noResultsText.classList.add('-active');
                            }
                        } else {
                            if (loadMoreBtn) {
                                loadMoreBtn.removeAttribute('data-reset');
                                reset = null;
                            }
                            resultsText.classList.add('-active');
                            if (noResultsText)
                                noResultsText.classList.remove('-active');
                        }
                    }
                }

                // Shuffle setup TODO: init on select click;
                let shuffleInstance;
                //let shuffleInitialized = false;
                    if (filters) {
                        const isWeddings = filters.classList.contains('weddings__filter-group');
                        // TODO: Find a way to intial everything long after page
                        // filters.addEventListener('click', function(ev){
                        //     console.log('filter click');
                        //     if(!shuffleInitialized){
                        //         console.log('FIRST INIT');
                        //         shuffleInstance = new Shuffle(document.getElementById(resultsId), {
                        //             itemSelector: '.js-shuffle__result',
                        //             sizer: '.js-shuffle__sizer',
                        //             columnThreshold: 1,
                        //             delimiter: ',',
                        //             filterMode: 'all'
                        //         });
                        //         shuffleInitialized = true;
                        //     }
                        // });
                        if (isWeddings) {
                            shuffleInstance = new Shuffle(document.getElementById(resultsId), {
                                itemSelector: '.js-shuffle__result',
                                sizer: '.js-shuffle__sizer',
                                columnThreshold: 1,
                                delimiter: ',',
                                filterMode: 'all'
                            });
                        } else {
                            shuffleInstance = new Shuffle(document.getElementById(resultsId), {
                                itemSelector: '.js-shuffle__result',
                                columnThreshold: 1,
                                delimiter: ',',
                            });
                        }
                    } else {
                        shuffleInstance = new Shuffle(document.getElementById(resultsId), {
                            itemSelector: '.js-shuffle__result',
                            sizer: '.js-shuffle__sizer',
                            columnThreshold: 1,
                            delimiter: ',',
                        });
                    }
                
                // Check for filters
                if (filters) {
                    let selectedFilters = [];
                    let selectedDateRange = '';
                    let submittedOnce = false;
                    let filterDateRange = [];
                    let hasTag = false;
                    let datesInRange = false;

                    // Programtically trigger select changes
                    function triggerChange(element) {
                        let changeEvent = new Event('change');
                        element.dispatchEvent(changeEvent);
                    }

                    // Custom select functionality
                    // TODO: Remove or adjust
                    function initSelects(selects) {
                        
                        for (let select of selects) {
                            
                            const native = select.querySelector('.js-select__native');

                            if (native) {

                                native.onclick = () => {
                                    
                                    let thisSelect = select;
                                    for (let select of selects) { 
                                        if(thisSelect != select){
                                            select.classList.remove('-open');
                                            select.querySelector('.js-select__native').classList.remove('-focused');
                                            //native.blur();
                                        } 
                                        
                                    }
                                    select.classList.add('-open');
                                    native.classList.add('-focused');
                                };
                                native.onchange = () => {
                                    select.classList.remove('-open');
                                    native.classList.remove('-focused');
                                };

                                const custom = select.querySelector('.js-select__custom');
                                const customOptions = custom.querySelectorAll('a');

                                if (customOptions.length > 0) {

                                    for (let customOption of customOptions) {

                                        if (!customOption.classList.contains('select__faux')) {
                                            customOption.onclick = (event) => {
                                                event.preventDefault();
                                                const group = customOption.getAttribute('data-group');
                                                const text = customOption.innerHTML;
                                                native.value = group;
                                                triggerChange(native);
                                                custom.querySelector('li:first-child a').innerHTML = text;
                                            };
                                        }
                                        else {
                                            customOption.onclick = (event) => {
                                                event.preventDefault();
                                                select.classList.remove('-open');
                                                native.classList.remove('-focused');
                                            };
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Reset filters functionality
                    function resetFilters(selects, shuffleInstance, filtersCounter, resultsCounter, resultsText, resultsList) {
                        if (selects.length > 0) {
                            for (let select of selects) {
                                let selectNative = select.querySelector('.js-select__native');
                                selectNative.value = '';
                                triggerChange(selectNative);
                            }
                        }
                        
                        let resultsItems = resultsList.querySelectorAll('.js-shuffle__results');
                        const loadMoreBtn = resultsList.parentElement.querySelector('.js-shuffle__btn');

                        shuffleInstance.filter();
                        updateCount(null, shuffleInstance.visibleItems, null, resultsCounter, resultsText, false, true);
                        selectedFilters = [];
                        selectedDateRange = '';
                        submittedOnce = false;
                        filterDateRange = [];
                    }

                    // Submit filters functionality
                    function submitFilters(submit, resets, selects, shuffleInstance, filtersCounter, resultsCounter, resultsText, filtersLightbox, results, loadMore) {
                        let i = 0;
                        let shuffleFilters = [];
                        
                        if (selects.length > 0) {
                            for (let select of selects) {
                                const selectNative = select.querySelector('.js-select__native');

                                if (selectNative.value) {
                                    shuffleFilters.push(selectNative.value);
                                    i++;
                                }
                            }
                        }

                        if (!loadMore) {
                            Shuffle.FilterMode.ALL;
                        }
                        if (shuffleFilters.length > 0) {
                            shuffleInstance.filter(shuffleFilters);
                        } else {
                            shuffleInstance.filter();
                        }

                        let filteredResults = results.querySelectorAll('.shuffle-item--visible.-visible');
                        if (!loadMore) {
                            filteredResults = results.querySelectorAll('.shuffle-item--visible');
                        }

                        if (filteredResults.length > 0) {
                            if (loadMore) {
                                if (filteredResults != shuffleInstance.visibleItems)
                                    updateCount(shuffleFilters.length, filteredResults.length, filtersCounter, resultsCounter, resultsText, true, false)
                                else
                                    updateCount(shuffleFilters.length, shuffleInstance.visibleItems, filtersCounter, resultsCounter, resultsText, true, false);
                            } else {
                                if (filteredResults != shuffleInstance.visibleItems)
                                    updateCount(null, filteredResults.length, filtersCounter, resultsCounter, resultsText, true, false)
                                else
                                    updateCount(null, shuffleInstance.visibleItems, filtersCounter, resultsCounter, resultsText, true, false);
                            }
                        } else {
                            updateCount(0, 0, filtersCounter, resultsCounter, resultsText, true, false);
                        }
                    }

                    // Activate filter controls for a set of results
                    function initFilterControls(filterGroup, shuffleInstance, filtersCounter, resultsCounter, resultsText, filtersLightbox, filterLightboxTrigger, results) {

                        if (filterGroup) {
                            
                            let resets;
                            resets = document.querySelectorAll('.js-filters__btn-reset');

                            // Gather filter controls
                            const selects = filterGroup.querySelectorAll('.js-select__wrap');
                            const submit = filterGroup.querySelector('.js-filters__btn-submit');


                            if (selectedFilters.length > 0) {

                                for (let filter of selectedFilters) {
                                    
                                    for (let select of selects) {
                                        const options = select.querySelectorAll('option');
                                        for (let option of options) {
                                            if (option.getAttribute('data-group') === filter) {
                                                option.selected = true;
                                                triggerChange(select);
                                            }
                                        }
                                    }
                                }
                            }

                            // Initiate all filter controls
                            if (selects.length > 0)
                                initSelects(selects);

                            if (resets.length > 0) {

                                for (let reset of resets) {
                                    reset.onclick = () => resetFilters(selects, shuffleInstance, filtersCounter, resultsCounter, resultsText, results);
                                }
                            }
                            if (submit)
                                submit.onclick = () => submitFilters(submit, resets, selects, shuffleInstance, filtersCounter, resultsCounter, resultsText, filtersLightbox, results, loadMore);

                            // Update counters with visible count on page load
                            window.addEventListener('load', () => {
                                let thisCount =  document.querySelectorAll('.js-shuffle__result').length;
                                updateCount(0, thisCount, filtersCounter, resultsCounter, resultsText, true, false);   updateCount(0, thisCount, filtersCounter, resultsCounter, resultsText, true, false); 
                            });
                        }
                    }

                    // Check for lightbox content
                    const filtersLightbox = filters.parentElement;
                    
                    initFilterControls(filters, shuffleInstance, null, resultsCounter, resultsCounter, null, null, results);
                }
            }// End loop of result group
        }
    }
}
export default Weddings;
