import Util from "../utils/util.js";
import Swiper, { Navigation, Scrollbar, Mousewheel } from 'swiper';
//import Swiper from 'swiper/bundle';
class CustomCards {
    constructor() {
        this.initCustomCards();
    }
    initCustomCards(){
        let customCards = Util.$('.js-custom-card');
        if(customCards.length> 0){
            customCards.forEach(el => {
                let slider = Util.$('.js-custom-card__slider', el);
                if(slider.length > 0){
                    let activeSlideIndicator = Util.$('.js-feature-media__ctrl-count-current', el)[0];
                    let totalSlideIndicator = Util.$('.js-feature-media__ctrl-count-total', el)[0];
                    let slideCount = Util.$('.js-glide__slide', el).length;
                    let current = 1;
                    let activeSlide = '';
                    let canShift = false;
                    let thisSlider = "";
                    const swiper = new Swiper('.swiper', {
                        modules: [Navigation, Scrollbar, Mousewheel],
                        slidesPerView: "auto",
                        spaceBetween: 30,
                        mousewheel: {
                            forceToAxis: true,

                        },
                        // Navigation arrows
                        navigation: {
                          nextEl: '.js-ctrl-next',
                          prevEl: '.js-ctrl-prev',
                        },
                      
                        // And if we need scrollbar
                        scrollbar: {
                            el: ".swiper-scrollbar"
                        },
                      });
                    // thisSlider = new Glide(el, {
                    //     rewind : false,
                    //     bound: true,
                    //     gap: 30,
                    //     perView: 4,
                    //     perTouch: 4,
                    //     touchRatio: 1,
                    //     breakpoints: {
                    //         1024: {
                    //           perView: 3,
                    //           perTouch: 3,
                    //         },
                    //         700: {
                    //             perView: 2,
                    //             perTouch: 2
                    //         },
                    //         500: {
                    //           perView: 1,
                    //           perTouch: 1
                    //         }
                    //       }
                    // });

                    // thisSlider.on('run.before', function(ev){
                    //     // activeSlide = Util.$('.glide__slide.glide__slide--active', el)[0];
                        
                    //     // if(ev.direction === ">"){
                    //     //     if(current < slideCount){
                    //     //         current = current + 1;
                    //     //         canShift = true;
                    //     //     }else{
                    //     //         canShift = false;
                    //     //     }
                            
                    //     // }else{
                    //     //     if(current > 1){
                    //     //         current = current - 1;
                    //     //         canShift = true;
                    //     //     }else{
                    //     //         canShift = false;
                    //     //     }
                    //     // }
                    //     // if(canShift){
                    //     //     if(!showMultiple){
                    //     //         activeSlide.classList.add('-leaving');
                    //     //     }
                    //     //     if (activeSlideIndicator)
                    //     //         activeSlideIndicator.textContent = current;
                    //     // }
                        
                    // });
                    // thisSlider.on('run.after', function(ev){
                    //     if(canShift && !showMultiple){
                    //         activeSlide.classList.remove('-leaving');
                    //     }
                    // });
                    // thisSlider.on('mount.before', function(ev){
                    //     // if (activeSlideIndicator)
                    //     //     activeSlideIndicator.textContent = current;
                    //     // if (totalSlideIndicator)
                    //     //     totalSlideIndicator.textContent = slideCount;
                    // });
                    // thisSlider.mount();
                }
            });
        }
        
    }
}
export default CustomCards;